import { cx } from 'class-variance-authority'

import aws from '~/assets/tech/aws.svg?component'
import docker from '~/assets/tech/docker.svg?component'
import chatgpt from '~/assets/tech/chatgpt.svg?component'
import lambda from '~/assets/tech/lambda.svg?component'
import postgresql from '~/assets/tech/postgresql.svg?component'
import next from '~/assets/tech/next.svg?component'
import nuxt from '~/assets/tech/nuxt.svg?component'
import redis from '~/assets/tech/redis.svg?component'
import stripe from '~/assets/tech/stripe.svg?component'
import cloudfront from '~/assets/tech/cloudfront.svg?component'
import dynamodb from '~/assets/tech/dynamodb.svg?component'
import github from '~/assets/tech/github.svg?component'
import mongodb from '~/assets/tech/mongodb.svg?component'
import node from '~/assets/tech/node.svg?component'
import react from '~/assets/tech/react.svg?component'
import strapi from '~/assets/tech/strapi.svg?component'
import vue from '~/assets/tech/vue.svg?component'

interface Logos {
  [key: string]: {
    label: string
    component: Component | string
  }
}
interface Logo {
  label: string
  component: Component | string
}
const logos:Logos = {
  aws : {
    label: 'AWS',
    component: aws,
  },
  docker:{
    label: 'Docker',
    component: docker,
  },
  chatgpt:{
    label: 'ChatGPT',
    component: chatgpt,
  },
  lambda:{
    label: 'AWS Lambda',
    component: lambda,
  },
  postgresql :{
    label: 'PostgreSQL',
    component: postgresql,
  },
  next:{
    label: 'NextJS',
    component: next,
  },
  nuxt:{
    label: 'NuxtJS',
    component: nuxt,
  },
  redis:{
    label: 'Redis',
    component: redis,
  },
  stripe:{
    label: 'Stripe',
    component: stripe,
  },
  cloudfront:{
    label: 'Cloudfront',
    component: cloudfront,
  },
  dynamodb:{
    label: 'DynamoDB',
    component: dynamodb,
  },
  github:{
    label: 'Github',
    component: github,
  },
  mongodb:{
    label: 'MongoDB',
    component: mongodb,
  },
  node:{
    label: 'NodeJS',
    component: node,
  },
  react:{
    label: 'ReactJS',
    component: react,
  },
  strapi:{
    label: 'Strapi',
    component: strapi,
  },
  vue:{
    label: 'VueJS',
    component: vue
  },
}

type IO = 0 | 1
type EmptyCell = (t?: IO, r?: IO, b?: IO, l?: IO) => { type: 'empty' | 'gray' | 'white'; class: string }
type LogoCell = (
  logo: Logo,
  t?: IO,
  r?: IO,
  b?: IO,
  l?: IO,
) => { type: 'logo' | 'big-logo'; class: string; icon: Component | string, label: string }

function NormalCell(type: 'empty' | 'gray' | 'white'): EmptyCell {
  const _type = type

  return (t = 1, r = 0, b = 0, l = 1) => {
    const classes: string[] = []
    if (b) classes.push('border-b')
    if (r) classes.push('border-r')
    if (l) classes.push('border-l')
    if (t) classes.push('border-t')

    if (_type === 'gray') classes.push('bg-gray-100')

    return {
      type: _type,
      class: cx(classes),
    }
  }
}
function LogoCell(type: 'logo' | 'big-logo'): LogoCell {
  const _type = type

  return (logo, t = 1, r = 0, b = 0, l = 1) => {

    const classes: string[] = []
    if (b) classes.push('border-b')
    if (r) classes.push('border-r')
    if (l) classes.push('border-l')
    if (t) classes.push('border-t')

    if (_type === 'big-logo') classes.push('span-2 w-full h-full')

    return {
      type: _type,
      icon: logo.component,
      label: logo.label,
      class: cx(classes),
    }
  }
}

/** empty cell */
export const e = NormalCell('empty')
/** gray cell */
export const g = NormalCell('gray')
/** white cell */
export const w = NormalCell('white')

/** logo cel */
export const l = LogoCell('logo')
/** big logo cell */
export const b = LogoCell('big-logo')

export type Grid = (ReturnType<EmptyCell> | ReturnType<LogoCell>)[][]

// prettier-ignore
export const desktopGrid: Grid = [
  [e(0, 0, 0, 0), e(0, 0, 0, 0), e(0, 0, 0, 0), g(1, 0, 0, 1), e(0, 0, 0, 1), w(1, 0, 0, 0), l(logos.github, 1, 0, 0, 1), e(0, 0, 0, 1), e(0, 0, 0, 0),                 e(0, 0, 0, 0),                  l(logos.nuxt, 1, 0, 0, 1), e(0, 0, 0, 1),                   b(logos.node, 1, 0, 0, 1),                               l(logos.chatgpt, 1, 0, 0, 1),  w(1, 0, 0, 1),               w(1, 0, 0, 1), w(1, 0, 0, 0), e(0, 0, 0, 1), w(1, 0, 0, 1), e(0, 0, 0, 1), g(1, 1, 0, 1)],
  [e(0, 0, 0, 0), g(1, 0, 0, 1), w(1, 0, 0, 1), e(1, 0, 0, 1), e(0, 0, 0, 0), e(1, 0, 0, 0), l(logos.stripe, 1, 0, 0, 1), e(0, 0, 0, 1), l(logos.aws, 1, 0, 0, 1),      b(logos.react, 1, 0, 0, 1),                                l(logos.cloudfront, 1, 0, 0, 1),                                                          g(1, 0, 0, 1),                  l(logos.lambda, 1, 0, 0, 1), e(1, 0, 0, 1), e(1, 0, 0, 0), e(0, 0, 0, 0), e(1, 0, 0, 0), w(1, 0, 0, 1), e(1, 0, 0, 1)],
  [e(0, 0, 0, 0), w(1, 0, 0, 1), e(1, 0, 0, 1), w(1, 0, 0, 1), e(0, 0, 0, 1), e(0, 0, 0, 0), b(logos.vue, 1, 0, 1, 1),                   l(logos.docker, 1, 0, 0, 1),                                                              l(logos.dynamodb, 1, 0, 0, 1),   l(logos.mongodb, 1, 0, 0, 1), l(logos.next, 1, 0, 0, 1), l(logos.postgresql,1, 0, 0, 1), w(1, 0, 0, 1),               w(1, 0, 0, 1), e(0, 0, 0, 0), w(1, 0, 0, 1), e(0, 0, 0, 1), g(1, 0, 0, 1), e(0, 0, 0, 1)],
  [w(1, 0, 1, 1), e(1, 0, 0, 1), e(0, 0, 0, 0), e(1, 0, 0, 0), g(1, 0, 1, 1), w(1, 0, 1, 0),                                             g(1, 0, 1, 1),                 l(logos.redis, 1 , 0, 1, 1),     g(1, 0, 1, 1),            e(1, 0, 0, 1),                   l(logos.strapi, 1, 0, 1, 1),  w(1, 0, 1, 1),             e(1, 0, 0, 1),                  g(1, 0, 1, 1),               e(1, 0, 0, 1), g(1, 0, 1, 0), e(1, 0, 0, 1), w(1, 0, 1, 1), e(1, 0, 0, 1), e(0, 0, 0, 0)]
]

// prettier-ignore
export const lgGrid: Grid = [
  [l(logos.github, 1, 0, 0, 1), e(0, 0, 0, 1), e(0, 0, 0, 0),                 e(0, 0, 0, 0),                   l(logos.nuxt, 1, 0, 0, 1), e(0, 0, 0, 1),                   b(logos.node, 1, 0, 0, 1),                               l(logos.chatgpt, 1, 0, 0, 1),  w(1, 0, 0, 1),        ],
  [l(logos.stripe, 1, 0, 0, 1), e(0, 0, 0, 1), l(logos.aws, 1, 0, 0, 1),      b(logos.react, 1, 0, 0, 1),                                 l(logos.cloudfront, 1, 0, 0, 1),                                                          g(1, 0, 0, 1),                  l(logos.lambda, 1, 0, 0, 1),],
  [b(logos.vue, 1, 0, 1, 1),                   l(logos.docker, 1, 0, 0, 1),                                                               l(logos.dynamodb, 1, 0, 0, 1),   l(logos.mongodb, 1, 0, 0, 1), l(logos.next, 1, 0, 0, 1), l(logos.postgresql,1, 0, 0, 1), w(1, 0, 0, 1),        ],
  [                                            g(1, 0, 1, 1),                 l(logos.redis, 1 , 0, 1, 1),     g(1, 0, 1, 1),             e(1, 0, 0, 1),                   l(logos.strapi, 1, 0, 1, 1),  w(1, 0, 1, 1),             e(1, 0, 0, 1),                  g(1, 0, 1, 1),        ]
]

// prettier-ignore
export const tabletGrid: Grid = [
  [l(logos.next, 1, 0, 0, 0),       w(1, 0, 0, 1),                 l(logos.strapi, 1, 0, 0, 1), l(logos.nuxt, 1, 0, 0, 1), b(logos.node, 1, 0, 0, 1),                                      l(logos.lambda, 1, 0, 0, 1)   ],
  [l(logos.github, 1, 0, 0, 0),     b(logos.react, 1, 0, 0, 1),                                 l(logos.aws, 1, 0, 0, 1),                                                                  w(1, 0, 0, 1)           ],
  [l(logos.docker, 1, 0, 0, 0),                                                                 g(1, 0, 0, 1),             l(logos.postgresql, 1, 0, 0, 1), l(logos.chatgpt, 1, 0, 0, 1), w(1, 0, 0, 1)           ],
  [g(1, 0, 0, 0),                   l(logos.stripe, 1, 0, 0, 1),   w(1, 0, 1, 1),               b(logos.vue, 1, 0, 1, 1),                                   g(1, 0, 0, 1),                 l(logos.dynamodb, 1, 0, 0, 1) ],
  [l(logos.cloudfront, 1, 0, 1, 0), l(logos.mongodb, 1, 0, 1, 1),  g(1, 0, 0, 1),                                                                           l(logos.redis, 1, 0, 1, 1),    g(1, 0, 1, 1)           ],
]

// prettier-ignore
export const mobileGrid: Grid = [
  [l(logos.github, 1, 0, 0, 0),   g(1, 0, 0, 1),                  b(logos.node, 1, 0, 0, 1)                              ],
  [w(1, 0, 0, 0),                 l(logos.aws, 1,0, 0, 1),                                                         ],
  [b(logos.vue, 1, 0, 0, 0),                                      l(logos.strapi, 1,0, 0, 1),   l(logos.next, 1,0, 0, 1)       ],
  [                                                               l(logos.chatgpt, 1,0, 0, 1), l(logos.docker, 1,0, 0, 1)     ],
  [g(0, 0, 0, 1),                 l(logos.stripe, 1,0, 0, 1),     w(1, 0, 0, 1),                l(logos.postgresql, 1,0, 0, 1) ],
  [l(logos.dynamodb, 1, 0, 0, 0), l(logos.cloudfront, 1,0, 0, 1), b(logos.react, 1, 0, 0, 1)                             ],
  [g(0, 0, 0, 1),                 l(logos.nuxt, 1,0, 0, 1),                                                        ],
  [l(logos.lambda, 0, 0, 1, 1),   g(1, 0, 1, 1),                  l(logos.redis, 1, 0, 1, 1),   g(1, 0, 1, 1)            ],
]
