<script setup lang="ts">
import type { Technology_Plain } from '@webamboos/admin'
import { mobileGrid, tabletGrid, desktopGrid, lgGrid } from './cell'

const { tech } = useHome()
</script>

<template>
  <Container v-if="tech" class="screen-content pointer-events-none flex flex-col items-center space-y-6 px-0 py-16">
    <div class="mb-8 flex flex-col items-center justify-center text-center">
      <Heading as="h2" size="caption" class="mb-2">{{ tech.title }}</Heading>

      <Heading as="h3" size="medium" class="mb-4 flex flex-col px-10 lg:px-0">
        {{ tech.headline }}
      </Heading>

      <Paragraph size="medium" class="mb-8 max-w-xl px-8 lg:px-0">
        {{ tech.body }}
      </Paragraph>
    </div>

    <TechPixelGrid :pattern="desktopGrid" class="!pointer-events-auto z-0 hidden xl:grid" />
    <TechPixelGrid :pattern="lgGrid" class="!pointer-events-auto z-0 hidden lg:grid xl:hidden" />
    <TechPixelGrid :pattern="tabletGrid" class="!pointer-events-auto z-0 hidden sm:grid lg:hidden" />
    <TechPixelGrid :pattern="mobileGrid" class="!pointer-events-auto z-0 grid sm:hidden" />
  </Container>
</template>
