<script setup lang="ts">
const props = defineProps<{
  animate?: boolean
}>()
const element = ref<HTMLDivElement>()
function handleMove(e: MouseEvent) {
  if (!props.animate) return
  const element = e.currentTarget as HTMLDivElement
  //@ts-ignore
  const x = e.layerX
  //@ts-ignore
  const y = e.layerY

  const yRotation = 32 * ((x - element.clientWidth / 2) / element.clientWidth)

  /* Calculate the rotation along the X-axis */
  const xRotation = -32 * ((y - element.clientHeight / 2) / element.clientHeight)

  /* Generate string for CSS transform property */
  const string = 'perspective(500px) scale(1.1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'

  /* Apply the calculated transformation */
  element.style.transform = string
}

function handleOut(e: MouseEvent) {
  if (!props.animate) return
  const element = e.currentTarget as HTMLDivElement
  element.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)'
}
</script>

<template>
  <div
    ref="element"
    @mousemove="handleMove"
    @mouseout="handleOut"
    :class="[animate && 'hover:bordershadow-lg-gray hover:z-10 hover:bg-gray-50']"
    :style="{ transition: 'box-shadow 0.1s, transform 0.1s' }"
    class="item-center flex justify-center"
  >
    <slot />
  </div>
</template>
