<script setup lang="ts">
import { TooltipArrow, TooltipContent, TooltipProvider, TooltipPortal, TooltipRoot, TooltipTrigger } from 'radix-vue'
import type { Grid } from './cell'

const props = defineProps<{
  pattern: Grid
}>()

const rows = props.pattern
const columnsTotal = rows[0].length

const rowsTotal = rows.length
</script>

<template>
  <div class="columns grid">
    <template v-for="(row, i) of  rows " :key="i">
      <template v-for="(cell, j) of  row " :key="j">
        <Pixel class="items-center flex justify-center" :animate="['logo', 'big-logo'].includes(cell.type)"
          :class="[cell.class]">
          <TooltipProvider v-if="cell.type === 'logo' || cell.type === 'big-logo'" :delayDuration="100">
            <TooltipRoot>
              <TooltipTrigger class="w-full h-full" :aria-label="$t('home.tech.ariaLabel', { tech: cell.label })">
                <component :is=" cell.icon" class="pointer-events-none m-auto"
                  :class="[cell.type === 'big-logo' ? 'w-2/4' : 'w-3/5']" />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent as-child
                  class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-grass11 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none bordershadow-lg-gray will-change-[transform,opacity] z-10"
                  :side-offset="5" side="bottom" delayDuration="200">
                  <ul>
                    {{ cell.label }}
                    <TooltipArrow class="fill-white" size="8" />
                  </ul>
                </TooltipContent>
              </TooltipPortal>
            </TooltipRoot>
          </TooltipProvider>
        </Pixel>
      </template>
    </template>
  </div>
</template>

<style>
.columns {
  grid-template-columns: repeat(v-bind(columnsTotal + 1), calc((100vw - 32px) / v-bind(columnsTotal + 1)));
  grid-template-rows: repeat(v-bind(rowsTotal), calc((100vw - 32px) / v-bind(columnsTotal + 1)));
}

@media screen(md) {
  .columns {
    grid-template-columns: repeat(v-bind(columnsTotal + 1), calc((100vw - 64px) / v-bind(columnsTotal + 1)));
    grid-template-rows: repeat(v-bind(rowsTotal), calc((100vw - 64px) / v-bind(columnsTotal + 1)));
  }
}

@media screen(xl) {
  .columns {
    grid-template-columns: repeat(v-bind(columnsTotal + 1), 100px);
    grid-template-rows: repeat(v-bind(rowsTotal), 100px);
  }
}

.span-2 {
  @apply col-span-2 row-span-2;
}
</style>
